@import "definitions";

$button_bg: $highlight_color;                               // Button Farbe
$button_font_color: $highlight_font_color;                  // Button Schriftfarbe
$button_bg_dark: $theme_color;                              // dunkler Button
$button_bg_dark_font_color: $theme_font_color;              // dunkler Button Schriftfarbe
$button_bg_hover: $highlight_color_hover;                   // Button Hoverfarbe
$button_bg_hover_font_color: $highlight_font_color_hover;   // Button Hover Schriftfarbe

/*Button*/

button::-moz-focus-inner {
    border:0;
    padding:0;
}

button.button, a.button{ 
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    font-size: inherit;  
    font-family: inherit;
    outline: none;
    font-family: $font-family;
    i{
        margin-right: $mar_small;
    }
}

button.button,
a.button,
span.button { 
    margin: 0;
    border: none;
    padding: $pad_small $pad; 
    text-transform: none;
    color: $button_font_color; 
    background-color: $button_bg; 
    display: inline-block;
    vertical-align: top; 
    cursor: pointer;
    box-shadow: $main_box_shadow;
    &:hover, &:focus, &:active{
     background-color: $button_bg_hover;
     color: $button_bg_hover_font_color;
    }
    &.dark{
       background-color: $button_bg_dark;
       color: $button_bg_dark_font_color;
       &:hover, &:focus, &:active{
           background-color: $button_bg_hover;   
           color: $button_bg_hover_font_color;
       }     
    }

    &.disabled{
        background-color: $button_bg_dark;
        color: darken($button_bg_dark_font_color, 20);
        cursor: default;
        &:hover, &:focus, &:active{
            background-color: $button_bg_dark;
            color: darken($button_bg_dark_font_color, 20);
        }     
     }

    /*Umkreissuche*/
    &.ratio_search{
        box-shadow: none;
        background: #fff;
        color: $base_font_color;
        border: $border;
        > label{
              cursor: pointer;
          }
    }
    
    & > .button_add {
        @include sl-icon($fa-var-plus);
    }
    & > .button_save {
        @include sl-icon($fa-var-floppy-o);
    }
    & > .button_create {
        @include sl-icon($fa-var-file-text);
    }
    & > .button_back {
        @include sl-icon($fa-var-reply);
    }
    & > .button_delete {
        @include sl-icon($fa-var-close);
    }
    & > .button_export {
        @include sl-icon($fa-var-sign-out);
    }
    & > .button_import {
        @include sl-icon($fa-var-sign-in);
    }
    & > .button_copy {
        @include sl-icon($fa-var-copy);
    }
    & > .button_move {
        @include sl-icon($fa-var-arrows);
    }
    & > .button_view {
        @include sl-icon($fa-var-search);
    }
    & > .button_edit {
        @include sl-icon($fa-var-edit);
    }
    & > .button_search {
        @include sl-icon($fa-var-search);
    }
    & > .button_download {
        @include sl-icon($fa-var-download);
    }
    & > .button_upload {
        @include sl-icon($fa-var-upload);
    }
    & > .button_send {
        @include sl-icon($fa-var-send);
    }
    & > .button_trash {
        @include sl-icon($fa-var-trash);
    }
    & > .button_add_to_basket {
        @include sl-icon($fa-var-star-o);
    }
    & > .button_remove_from_basket {
        @include sl-icon($fa-var-star);
    }
}

/*Umkreissuche*/
.button_globe {
    @include sl-icon($fa-var-globe);
    font-size: $big_icon_size;
}

/*Ladensymbol*/

.loading{
    @include sl-icon($fa-var-spinner);
    font-size: $font_size_big_icon;
    margin-right: $mar_small;
}

*.fa.disabled{
    color:#aaa;  
}


div.entry_top_buttons {
    margin-bottom: $mar;
}

.page_heading_box .actions_list{
    margin-top: -6px;    
}

.arrow_up{
    @include sl-icon($fa-var-angle-up);       
}
.arrow_down{
    @include sl-icon($fa-var-angle-down);
}

.minimal_button, .button_minimal {
    display:inline-block;
    padding: $pad_tiny 0;
    min-width:1.8em;
    text-align:center;
    border: $border;
    cursor: pointer;
    
    &:hover {
        color: #fff;
        background-color: $button_bg;
    }

    &.with_label {
        padding: $pad_tiny $pad_small;
        min-width: 5em;
        text-align: left;
    }
}
